import {
    myProfile
} from '@/utils/user.js';
import {
    getNotice
} from '@/utils/home.js';
import phonePopup from '@/components/phone.vue';
import setPopup from '@/components/set.vue';
import useClipboard from "vue-clipboard3"
import { provide, ref } from 'vue';
export default {
    name: "User",
    setup() {
        let showPhone = ref(false);
        let showSet = ref(false);
        provide('showphone', showPhone);
        provide('showset', showSet);
        return {
            showPhone,
            showSet
        }
    },
    components: {
        phonePopup,
        setPopup
    },
    data() {
        return {
            defaultAvator: require("../../../assets/img/user/home/default.png"),
            showInfo: false,
            userInfo: {},
            noticeList: '',
        }
    },
    created() {
        // 跳转登录页面
        // if (!this.$cookies.get('isLogin')) {
        //     this.$router.push({
        //         path: '/login'
        //     })
        // } else {
            this.initData();
            this.getNoticeFunc();
        // }
    },
    methods: {
        liClick(num) {
            if (num === 0) {
                return this.$toast('线上预告课程暂未开启，目前所有培训以线下代理商通知为准')
            } else if (num == 1) {
                return this.$toast('暂未开启，敬请期待！')
            } else {
                window.location.href = "/agreement/dt.html"
            }
        },
        // 获取公告
        getNoticeFunc() {
            let data = {
                pageNo: 1,
                pageSize: 3,
                type: 2
            }
            getNotice(data).then(res => {
                if (res && res.code == "0") {
                    this.noticeList = res.data || []
                }
            })
        },
        initData() {
            let data = {
                userId: this.$cookies.get('userId')
            };
            myProfile(data).then(res => {
                if (res && res.code == "0") {
                    this.userInfo = res.data || {}
                }
                this.userInfo.avator = this.userInfo.pic || this.defaultAvator
            })
        },
        setFun() {
            this.showSet = true;
        },
        phoneFun() {
            this.showPhone = true
        },
        async copyFun() {
            try {
                const { toClipboard } = useClipboard()
                await toClipboard(this.userInfo.inviteCode)
                this.$toast.success("已复制邀请码")
            } catch (e) {
                console.error(e)
            }
        }
    }
}