<template>
  <div class="second-user">
    <div class="box-name flex">
      <img :src="userInfo.avator" :style="{ opacity: userInfo.avator ? 1 : 0 }" class="box-name-left" />
      <div class="box-name-center">
        <div class="grade flex">
          <p class="portrait">{{ userInfo.name || "" }}</p>
        </div>
        <p class="phone">手机号：{{ userInfo.phone || "" }}</p>
        <div class="invite flex align-center">
          <p class="text">邀请码：{{ userInfo.inviteCode }}</p>
          <div class="copy flex align-center" @click="copyFun">
            <img src="@/assets/img/user/home/copy.png" class="copy-pic" />
            <p>复制</p>
          </div>
          <!-- <div class="copy flex align-center box-pic">
            <img src="@/assets/img/user/home/bao.png" />
            <p>生成海报</p>
          </div> -->
        </div>
      </div>
      <div class="box-name-right flex flex-right">
        <div class="right">
          <img
            src="@/assets/img/user/home/service.png"
            alt=""
            @click="phoneFun"
          />
          <img src="@/assets/img/user/home/set.png" @click="setFun"/>
        </div>
      </div>
    </div>
    <!-- 公告 -->
    <div v-if="noticeList.length > 0" class="home-main-notice">
      <van-swipe
        vertical
        class="notice-swipe"
        :autoplay="3000"
        :show-indicators="false"
      >
        <van-swipe-item
          class="flex flex-left align-center"
          v-for="item in noticeList"
          :key="item.id"
        >
          <img
            class="home-main-notice-icon"
            src="@/assets/img/home/notice.png"
            alt=""
          />
          <span class="home-main-notice-msg">{{ item.title }}</span>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="s-one flex align-center" @click="liClick(0)">
      <img src="@/assets/img/user/home/second/addr.png" class="left" />
      <p>培训安排</p>
      <img src="@/assets/img/user/home/second/right.png" class="right" />
    </div>
    <div class="s-two">
      <div class="t-li flex align-center" @click="liClick(1)">
        <img src="@/assets/img/user/home/second/book.png" class="left" />
        <p>新手教程</p>
        <img src="@/assets/img/user/home/second/right.png" class="right" />
      </div>
      <div class="t-li flex align-center" @click="liClick(2)">
        <img src="@/assets/img/user/home/second/collect.png" class="left" />
        <p>地推规范</p>
        <img src="@/assets/img/user/home/second/right.png" class="right" />
      </div>
      <div class="t-li flex align-center" @click="liClick(1)">
        <img src="@/assets/img/user/home/second/tip.png" class="left" />
        <p>业务概况说明</p>
        <img src="@/assets/img/user/home/second/right.png" class="right" />
      </div>
      <div class="t-li flex align-center" @click="liClick(1)">
        <img src="@/assets/img/user/home/second/news.png" class="left" />
        <p>结算说明</p>
        <img src="@/assets/img/user/home/second/right.png" class="right" />
      </div>
    </div>
    <phone-popup></phone-popup>
    <!-- 设置 -->
    <set-popup></set-popup>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>